/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://mznfc26x5nemviwt7px36txd74.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wc6ebzwx4ndddltykl7y5nolvm",
    "aws_cognito_identity_pool_id": "us-east-1:1a3ecd8f-4ce6-4f3d-aa3f-4d090f7a1bdd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2aCt0gMR6",
    "aws_user_pools_web_client_id": "3ptvpmc1ng9modpjtoboqoe9d8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
